import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import("../views/index.vue")
    }, {
        path: '/about',
        name: 'About',
        component: () => import("../views/about.vue")
    }, {
        path: '/notice',
        name: 'Notice',
        component: () => import("../views/notice.vue")
    }, {
        path: '/college',
        name: 'College',
        component: () => import("../views/college.vue")
    }, {
        path: '/download',
        name: 'Download',
        component: () => import("../views/download.vue")
    }, {
        path: '/notice/content/:id',
        name: 'NoticeDetail',
        component: () => import("../views/noticeDetail.vue")
    },{
        path: '/notice/content',
        name: 'NoticeDetail',
        component: () => import("../views/noticeDetail.vue")
    }
]

const router = new VueRouter({
    mode: "history",
    routes,
});
//抛出这个这个实例对象方便外部读取以及访问
export default router