<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

p, h3 {
  margin: 0;
}
.bict-btn {
  cursor: pointer;
  padding: 3px 7px;
  border: 0;
  background: #009CFA;
  border-radius: 3px;
  color: #ffffff;
  font-size: 14px;
  font-family: FZHei-B01T;
  font-weight: 400;
}

.el-loading-mask{
  background-color: rgba(0,0,0,0) !important;
}
</style>
