import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';
import locale from 'element-ui/lib/locale';
import enLocale from './en.json';
import zhLocale from './zh-cn.json';

Vue.use(VueI18n); // vue使用i18n模块
// 引入本地
const messages = {
    en: {
        ...enLocale, // es6的拓展运算符，相当于解析出每个对象
        ...elementEnLocale
    },
    zh: {
        ...zhLocale,
        ...elementZhLocale
    }
}

export function getLanguage() {
    const chooseLanguage = Cookies.get('language');

    if (chooseLanguage) {
        return chooseLanguage;
    }

    // if has not choose language
    const language = navigator.language || navigator.browserLanguage;
    const locales = Object.keys(messages);

    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
            return locale;
        }
    }
    return 'en';
}

export const setup = lang => { //切换语言的函数，lang为语言标识，en或者zh
    // 在此判断lang的值，如果未定义，则让lang默认为DEFAULT_LANG，目的是为了让用户在未选择语言的时候默认为英文。
    if(!lang) {
        lang = Cookies.get('language');
    }
    // 若lang有值，那么存入localStorage中，key为LOCALE_KEY,value为lang。
    Cookies.set('language', lang);
    Vue.config.lang = lang;
    i18n.locale = lang;
}

// 创建国际化实例
const i18n = new VueI18n({
    locale: getLanguage(), // set locale，默认中文
    messages // set locale messages。语言包
});
locale.i18n((key, value) => i18n.t(key, value));


export default i18n;