import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import utils from './utils';
import i18n from './lang';

Vue.prototype.$utils = utils
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
    router,
    i18n,
    render: h => h(App),
}).$mount('#app')
